let PROJECT_ID = process.env.SANITY_PROJECT_ID;
let DATASET = 'production';
let QUERY = encodeURIComponent('*[_type == "house"]');
let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

// fetch the content
export const getHousesData = () => {
  return fetch(URL)
    .then(res => res.json())
    .then(({ result }) => {
      return result;
    })
    .catch(err => {
      console.error(err);
      return [];
    });
};
