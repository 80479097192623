import React from 'react';
import { Loader } from '../loader/Loader';

const Table = ({ tableData, isLoading }) => {
  const baseUrl = window.location.origin;

  const tableBody = tableData.map((item, index) => {
    const rzut = new URL(`./static/rzuty/rzut-${item.houseNumber.toLowerCase()}.jpg`, baseUrl);

    return (
      <tr
        key={item.houseNumber}
        className={`${index === 0 ? 'main-table__first-row' : ''} ${
          item.houseStatus === 'sprzedany' ? 'main-table__highlighted' : ''
        }`}
      >
        <td>{item.houseNumber}</td>
        <td>{item.houseArea}</td>
        <td>{item.gardenArea}</td>
        <td>
          <a className="main-table__link" href={`${rzut}`} target="_blank">
            RZUT
          </a>
        </td>
        <td>{item.houseStatus}</td>
      </tr>
    );
  });

  return (
    <div className="main-table__wrapper">
      <table className="main-table">
        <thead>
          <tr className="main-table__header">
            <th>Budynek nr.</th>
            <th>Powierzchnia (m2)</th>
            <th>Powierzchnia działki (m2)</th>
            <th>Rzut</th>
            <th>Status</th>
          </tr>
        </thead>
        {!isLoading ? <tbody>{tableBody}</tbody> : null}
      </table>
      {isLoading ? (
        <div className="main-table__loader-wrapper">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default Table;
