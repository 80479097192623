import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ImageMapCloud } from './imageMapCloud';
import { ImageMapSvg } from './ImageMapSvg';
import { Loader } from '../loader/Loader';

const imageMapContainer = document.getElementById('image-map__container');

export const ImageMap = ({ housesData, isLoading }) => {
  const baseUrl = window.location.origin;

  const [cloudData, setCloudData] = useState({
    houseNumber: '',
    houseArea: 0,
    externalArea: 0,
    houseStatus: '',
    isVisible: false,
    top: 0,
    left: 0
  });

  const houseClickHandler = e => {
    const houseUrl = new URL(`./static/rzuty/rzut-${e.target.dataset.houseNumber.toLowerCase()}.jpg`, baseUrl);
    window.open(houseUrl, '_blank').focus();
  };

  const houseHoverHandler = e => {
    if (housesData.length > 0) {
      const houseData = housesData.filter(elem => {
        return elem.houseNumber === e.target.dataset.houseNumber.toUpperCase();
      });

      setCloudData({
        houseNumber: houseData[0].houseNumber,
        houseArea: houseData[0].houseArea,
        externalArea: houseData[0].gardenArea,
        houseStatus: houseData[0].houseStatus,
        isVisible: true,
        top: e.pageY - 50,
        left: e.pageX
      });
    }
  };

  const houseLeavehandler = e => {
    setCloudData({ ...cloudData, isVisible: false });
  };

  return ReactDOM.createPortal(
    <>
      {!isLoading ? <ImageMapCloud cloudData={cloudData} /> : null}
      <div className="image-map__wrapper">
        {isLoading ? (
          <div className="image-map__loader-wrapper">
            <Loader />
          </div>
        ) : null}
        <ImageMapSvg
          houseHoverHandler={houseHoverHandler}
          houseLeaveHandler={houseLeavehandler}
          houseClickHandler={houseClickHandler}
        />
      </div>
    </>,
    imageMapContainer
  );
};
