import { createRoot } from 'react-dom/client';
import React, { useState, useEffect } from 'react';
import { getHousesData } from '../common/api/getHousesData';
import Table from './table/table';
import { ImageMap } from './imageMap/ImageMap';

const sortByLetters = (a, b) => {
  const aLetter = a.houseNumber[a.houseNumber.length - 1];
  const bLetter = b.houseNumber[b.houseNumber.length - 1];

  if (aLetter < bLetter) {
    return -1;
  }
  if (aLetter > bLetter) {
    return 1;
  }
  return 0;
};

const sortByNumbers = (a, b) => {
  const aNumber = a.houseNumber.slice(0, a.houseNumber.length - 1);
  const bNumber = b.houseNumber.slice(0, b.houseNumber.length - 1);

  return aNumber - bNumber;
};

function App() {
  const [housesData, setHousesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getHousesData()
      .then(res => {
        if (res.length > 0) {
          const sortedData = res.sort(sortByLetters).sort(sortByNumbers);
          setHousesData(sortedData);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <ImageMap housesData={housesData} isLoading={isLoading} />
      <Table tableData={housesData} isLoading={isLoading} />;
    </>
  );
}

const root = createRoot(document.getElementById('main-table'));
root.render(<App />);
