import React from 'react';
import logo from '../../assets/logo.png';

export const ImageMapCloud = ({ cloudData }) => {
  return (
    <>
      {cloudData.isVisible && (
        <div className="cloud-container" style={{ top: cloudData.top, left: cloudData.left }}>
          <div className="cloud-container__top-container">
            <div className="cloud-container__logo-container">
              <img className="cloud-container__logo" src={logo} alt="logo inwestycji Bagry House" />
            </div>
            <div className="cloud-container__number-container">
              <p className="cloud-container__number">{`DOM nr. ${cloudData.houseNumber}`}</p>
            </div>
          </div>
          <p className="cloud-container__info">{`Powierzchnia ${cloudData.houseArea} m2`}</p>
          <p className="cloud-container__info">{`Ogród ${cloudData.externalArea} m2`}</p>
          <p className="cloud-container__status-info">
            Status: <span className="cloud-container__status">{`${cloudData.houseStatus}`}</span>
          </p>
        </div>
      )}
    </>
  );
};
