import React, { useState } from 'react';
import imageMap from '../../assets/map2.jpg';

export const ImageMapSvg = ({ houseHoverHandler, houseLeaveHandler, houseClickHandler }) => {
  return (
    <svg
      version="1.1"
      id="bagryMap"
      className="image-map__bagry-map"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1640 745"
      enableBackground="new 0 0 1640 745"
      xmlSpace="preserve"
    >
      <image overflow="visible" width="1640" height="745" xlinkHref={imageMap}></image>
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="1369.71,399.78 1369.71,641.06 1482.87,633.23 1482.87,410.45 1473.21,404.05 1458.72,399.78 "
        data-house-number="9a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="1230.92,649.6 1367.39,641.06 1367.39,399.78 1230.92,399.78 "
        data-house-number="9b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="1228.34,649.6 1095.69,656.09 1095.69,399.78 1228.34,399.78 "
        data-house-number="8a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="1092.75,399.78 1094.26,656.09 958.5,663.57 958.5,399.78 "
        data-house-number="8b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="817.04,399.78 817.04,669.53 955.83,663.57 955.83,399.78 "
        data-house-number="7a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="815.44,399.78 680.2,399.78 680.03,679.67 815.44,669.53 "
        data-house-number="7b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="678.43,399.78 678.08,679.67 542.66,686.43 542.66,399.78 "
        data-house-number="6a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <polygon
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        points="540.7,686.43 540.88,399.78 398.18,399.78 398.18,691.41 "
        data-house-number="6b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="151.38"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="113.17"
        height="288.26"
        data-house-number="5b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="266.95"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="131.58"
        height="288.26"
        data-house-number="5a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="400.93"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="139.59"
        height="288.26"
        data-house-number="4b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="542.66"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="135.77"
        height="288.26"
        data-house-number="4a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="681.18"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="134.52"
        height="288.26"
        data-house-number="3b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="818.64"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="137.46"
        height="288.26"
        data-house-number="3a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="959.03"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="133.81"
        height="288.26"
        data-house-number="2b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="1094.62"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="136.65"
        height="288.26"
        data-house-number="2a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="1233.41"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="132.38"
        height="288.26"
        data-house-number="1b"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
      <rect
        x="1367.93"
        y="53.43"
        className="image-map__item"
        opacity="0"
        fill="#5CE1E6"
        width="117.08"
        height="288.26"
        data-house-number="1a"
        onMouseEnter={houseHoverHandler}
        onMouseLeave={houseLeaveHandler}
        onClick={houseClickHandler}
      />
    </svg>
  );
};
